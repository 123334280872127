<template>
    <!-- 基本信息 -->
    <div class="examinee_box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item style="margin-bottom: 0px;">
                <span class="tips"><i class="iconfont">&#xe67b;</i>注：姓名、就读所在地、就读学校保存后不允许修改</span>
            </el-form-item>
            <el-form-item label="昵称" prop="NickName">
                <el-input v-model="ruleForm.NickName" placeholder="请输入昵称" clearable></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="StudentName">
                <el-input v-model="ruleForm.StudentName" placeholder="请输入姓名" clearable :disabled="isAdd"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="Sex" style="margin-bottom: 0px;">
                <el-radio-group v-model="ruleForm.Sex">
                    <el-radio :label="true" >男</el-radio>
                    <el-radio :label="false">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="就读所在地" prop="OptionsValue">
                <el-cascader v-model="ruleForm.OptionsValue" :options="AreaOptions" @change="handleChangeArea" clearable style="width:100%" :disabled="isAdd && disableS"></el-cascader>
            </el-form-item>
            <el-form-item label="就读学校" prop="SchoolValue">
                <el-select v-model="ruleForm.SchoolValue" placeholder="请选择" clearable style="width:100%" @change="handleChangeSchool" :disabled="isAdd && disableS">
                    <el-option v-for="item in SchoolData" :key="item.Id" :label="item.SchoolName" :value="item.Id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学校名称" v-if="otherSchool||ruleForm.SchoolValue=='000'">
                <el-input v-model="ruleForm.SchoolName" placeholder="请输入学校名称" clearable :disabled="isAdd && disableS"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import API from "@/api/config";
import { getStore } from "@/utils/utils";
import {Message} from 'element-ui';
export default{
    data(){
        return {
            ruleForm: {
                NickName: '',
                StudentName: '',
                Sex:null,
                SchoolName:'',
                OptionsValue:'',
                SchoolValue:''
            },
            AreaOptions:[],
            SchoolData:[],
            otherSchool:false,
            isAdd:true,
            disableS: true,
            rules: {
                NickName: [
                    { required: true, message: '请输入昵称', trigger: 'blur' }
                ],
                StudentName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Sex: [
                    { required: true, message: '请选择性别', trigger: 'blur' }
                ],
                OptionsValue: [
                    { required: true, message: '请选择就读所在地', trigger: 'change' }
                ],
                SchoolValue: [
                    { required: true, message: '请选择就读学校', trigger: 'change' }
                ],
            }
        }
    },
    mounted(){
        this.GetAreaOptions();// 获取省市县数据
        this.GetUserStudent();// 获取学生基本信息

    },
    methods:{
        // 获取省市县数据
        GetAreaOptions(){
            API.Options.GetAreaOptions().then(res => {
                if(res.code===0){
                    this.$nextTick(()=>{
                        res.data.forEach(p=>{
                            p.value = p.Id;
                            p.label = p.Name;
                            p.ChildrenList.forEach(c=>{
                                c.value = c.Id;
                                c.label = c.Name;
                                c.ChildrenList.forEach(d=>{
                                    d.value = d.Id;
                                    d.label = d.Name;
                                });
                                c.children = c.ChildrenList;
                            });
                            p.children = p.ChildrenList;
                        });
                        this.AreaOptions = res.data;
                    })
                    
                }
            });
        },
        // 省市县数据切换
        handleChangeArea(value){
            this.ruleForm.OptionsValue = value;
            this.otherSchool = false;
            this.ruleForm.SchoolName = '';
            this.GetSchoolOptions(value);// 获取学校数据
        },
        // 获取学校数据
        GetSchoolOptions(value){
            this.ruleForm.SchoolValue = [];
            let query = {
                provinceId: value[0],
                cityId: value[1],
                districtId: value[2]
            }
            API.Options.GetSchoolOptions(query).then(res => {
                if(res.code===0 && res.data!=null){
                    this.SchoolData = res.data;
                    this.SchoolData.push({
                        Id: '000',
                        SchoolName: '其他'
                    })
                }
            });
        },
        // 学校数据切换
        handleChangeSchool(value){
            if (value === '000') {
                this.otherSchool = true;
                this.ruleForm.SchoolName = '';
            }
            else{
                this.otherSchool = false;
                let obj = this.SchoolData.find(f=>f.Id==value);
                if(obj!=null){
                    this.ruleForm.SchoolName = this.SchoolData.find(f=>f.Id==value).SchoolName;
                }
            }
        },
        // 获取学生基本信息
        GetUserStudent(){
            API.Personal.GetUserStudent().then(res => {
                if(res.Code===0){
                    this.ruleForm.NickName = res.Data.NickName;
                    if(res.Data.Student!=null){
                        this.ruleForm.StudentName = res.Data.Student.StudentName;
                        this.ruleForm.SchoolName = res.Data.Student.SchoolName;
                        this.ruleForm.Sex = res.Data.Student.Sex;

                        let t = res.Data.Student
                        if (!t.ProvinceId || !t.CityId || !t.DistrictId) {
                            this.disableS = false
                        }
                        if (t.ProvinceId && t.CityId && t.DistrictId) {
                            this.disableS = true
                            this.ruleForm.OptionsValue = [res.Data.Student.ProvinceId,res.Data.Student.CityId,res.Data.Student.DistrictId];
                            this.GetSchoolOptions(this.ruleForm.OptionsValue);
                            this.ruleForm.SchoolValue = ((res.Data.Student.SchoolId=='0'||!res.Data.Student.SchoolId)?'000':res.Data.Student.SchoolId);
                        }
                        
                    }
                    else{
                        this.isAdd = false;
                    }
                    // // 是否初始化过账号
                    // if(res.Data.HasLoginName == false){
                    //     //显示引导设置账号和密码
                    //     this.$confirm('设置账号和密码后可使用账号密码登录，是否前往设置？', '提示', {
                    //         confirmButtonText: '前往设置',
                    //         cancelButtonText: '取消',
                    //         type: 'warning'
                    //     }).then(() => {
                    //         this.$router.push('/setaccount');
                    //     }).catch(() => {
                    //     });
                    // }
                }
            });
        },
        // 保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.otherSchool){
                        if(!this.ruleForm.SchoolName){
                            Message({type: 'error',message: '请输入就读学校！'});return false;
                        }
                    }
                    if(!this.isAdd){
                        this.$confirm('姓名、就读所在地、就读学校保存后不允许修改，请确认无误', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.submit();
                        }).catch(() => {
                        });
                    }
                    else{
                        this.submit();
                    }
                }
            });
        },
        // 提交数据
        submit(){
            let par = {
                UserId: JSON.parse(getStore('LoginData')).UserId,
                NickName: this.ruleForm.NickName,
                StudentName: this.ruleForm.StudentName,
                Sex: this.ruleForm.Sex,
                ProvinceId: this.ruleForm.OptionsValue[0],
                CityId: this.ruleForm.OptionsValue[1],
                DistrictId: this.ruleForm.OptionsValue[2],
                SchoolId: this.ruleForm.SchoolValue=='000'? undefined :this.ruleForm.SchoolValue,
                SchoolName: this.ruleForm.SchoolName
            };
            API.Personal.UpdateUserStudent(par).then(res =>{
                if(res.Code===0){
                    this.isAdd = true;
                    
                    this.disableS = true

                    if (this.$route.query.source === 'TB') {
                        this.$store.dispatch('user/judgeInfoComplete').then(() => {

                            Message({type: 'success',message: '保存成功！'});

                            this.$router.push('/')
                        })
                    } else {

                        let temp = Object.keys(this.$route.query)

                        temp.some((item, index) => {
                            if (item === 'redirect') {
                                temp.splice(index, 1)
                            }
                        })
                        
                        let tUrl = temp.reduce((pre, next) => {
                            pre = pre + next + '=' + this.$route.query[next]+'&'
                            return pre
                        }, '')
                        tUrl = tUrl.slice(0, -1)
                        
                        if(this.$route.query.redirect === '/openvip') {
                            API.User.CheckUserStudentComplate().then(({data}) => {
                                if (data.IsNeedComplateScore) {
                                    this.$router.push('/examinee?redirect=/openvip&'+tUrl)
                                } else {
                                    this.$router.push('/openvip?'+tUrl)
                                }
                            })

                            return
                        }
                        Message({type: 'success',message: '保存成功！'});
                        this.$router.push('/refresh');//刷新页面
                    }
                }
                else{
                    Message({type: 'error',message: res.Message});
                }
            });
        }
    }
}
</script>
<style lang="less" scoped>
.examinee_box{
    margin: 10px;
    border: 1px solid #e4e4e4;
    padding: 20px 0 0;
}
.examinee_box .el-form .el-form-item .tips{
    color: #757575;
    font-size: 12px;
    i{
        margin-right: 5px;
    }
}
</style>
<style lang="less">
.examinee_box .el-form .el-form-item{
    max-width: 80%;
    min-width: 400px;
}
.examinee_box .el-form .el-form-item .el-button--primary{
    width: 120px;
    background-color: rgba(var(--themecolor),0.85);
    border-color: rgba(var(--themecolor),0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}
.examinee_box .el-form .el-form-item .el-button--primary:hover{
    background-color: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
}
.examinee_box .el-form .el-form-item .el-radio__input.is-checked .el-radio__inner{
    border-color: rgb(var(--themecolor));
    background: rgb(var(--themecolor));
}
.examinee_box .el-form .el-form-item .el-radio__input .el-radio__inner:hover{
    border: 1px solid #DCDFE6;
}
.examinee_box .el-form .el-form-item .el-radio__input.is-checked + .el-radio__label{
    color: rgb(var(--themecolor));
}
.examinee_box .el-form .el-form-item .el-input__inner:focus{
    border-color: #ddd;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{
    border-color: rgba(var(--themecolor),0.95);
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
    color: rgb(var(--themecolor));
}
.el-select-dropdown__item.selected{
    color: rgb(var(--themecolor));
}
</style>